export const dAppName = '$CYBER Token Presale | Cyberpunk City';

export const PRESALE_CONTRACT_ADDRESS =
  'erd1qqqqqqqqqqqqqpgqyq5g6uvx0hzwdl0864sxpdtquv0hhaa5we0s5hpa90';
export const PRESALE_CONTRACT_ABI_URL = '/abi/token-shop.abi.json';
export const PRESALE_CONTRACT_NAME = 'TokenShop';

export const TOKEN_DECIMAL = 18;

export const TIMEOUT = 6000;

export const ENVIRONMENT = 'mainnet';
export const GATEWAY = 'https://api.elrond.com';
