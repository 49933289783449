import React, { useEffect } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { dAppName } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as CPCLogo } from './../../../assets/img/cyberpunk-city-logo.svg';
import { ReactComponent as Lightning } from './../../../assets/img/lightning.svg';

const Navbar = () => {
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };
  const handleLogin = () => {
    navigate(routeNames.unlock, { replace: true });
  };

  // useEffect(() => {
  //   window.location.href = 'https://kroganlaunchpad.com/cyberpunkcity';
  // });

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar className='navbar-cpc px-3 py-3'>
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={routeNames.home}
        >
          <CPCLogo className='cpc-logo' />
        </Link>

        <Nav className='ml-auto'>
          {isLoggedIn ? (
            <NavItem>
              <button
                className='is-style-cpc-btn-styled connect'
                onClick={handleLogout}
              >
                <Lightning className='mr-1' />
                DISCONNECT
              </button>
            </NavItem>
          ) : (
            <NavItem>
              <button
                className='is-style-cpc-btn-styled connect'
                onClick={handleLogin}
              >
                <Lightning className='mr-1' />
                CONNECT
              </button>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
  );
};

export default Navbar;
