import React, { useState, useEffect } from 'react';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { useGetPendingTransactions } from '@elrondnetwork/dapp-core/hooks/transactions/useGetPendingTransactions';
import useGetNetworkConfig from '@elrondnetwork/dapp-core/hooks/useGetNetworkConfig';
import { sendTransactions } from '@elrondnetwork/dapp-core/services';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils';
import {
  Account,
  TransactionHash,
  TransactionPayload,
  Transaction,
  Address,
  SmartContract,
  CodeMetadata,
  Code,
  AbiRegistry,
  U64Value,
  U32Value,
  StringValue,
  BigUIntValue,
  ContractFunction,
  AddressValue,
  SmartContractAbi,
  ResultsParser,
  TransactionWatcher,
  Interaction,
  TokenPayment,
  DeployArguments,
  TokenIdentifierValue,
  NumericalValue,
  EnumValue,
  TypedValue,
  List,
  BytesValue,
  ArgSerializer
} from '@elrondnetwork/erdjs';
import {
  ApiNetworkProvider,
  ProxyNetworkProvider,
  AccountOnNetwork
} from '@elrondnetwork/erdjs-network-providers';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import BigNumber from 'bignumber.js/bignumber.js';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import presaleAbi from 'abi/token-shop.abi.json';
import Time from 'components/Time';
import {
  PRESALE_CONTRACT_ADDRESS,
  PRESALE_CONTRACT_ABI_URL,
  PRESALE_CONTRACT_NAME,
  TIMEOUT,
  TOKEN_DECIMAL,
  GATEWAY
} from 'config';
import { routeNames } from 'routes';
import { SECOND_IN_MILLI } from 'utils/const';

import { convertWeiToEgld } from 'utils/convert';
import Video from './../../assets/img/cpc-video.mp4';

import './index.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: '3px',
  height: 25,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 8,
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

const Home = () => {
  const presaleId = 'cyber_presale_3';

  const navigate = useNavigate();
  const { address, account } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);
  const { hasPendingTransactions } = useGetPendingTransactions();
  const { network } = useGetNetworkConfig();
  const networkProvider = new ApiNetworkProvider(network.apiAddress);

  const handleLogin = () => {
    navigate(routeNames.unlock, { replace: true });
  };

  // load smart contract abi and parse it to SmartContract object for tx
  const [contractInteractor, setContractInteractor] = useState<any>(undefined);
  useEffect(() => {
    const ABI = presaleAbi as any;
    // console.log(ABI);
    (async () => {
      const abiRegistry = AbiRegistry.create(ABI);
      const abi = new SmartContractAbi(abiRegistry, [PRESALE_CONTRACT_NAME]);
      const contract = new SmartContract({
        address: new Address(PRESALE_CONTRACT_ADDRESS),
        abi: abi
      });
      setContractInteractor(contract);
    })();
  }, []); // [] makes useEffect run once

  const [isWhitelist, setIsWhitelist] = useState(false);
  // useEffect(() => {
  //   (async () => {
  //     if (!contractInteractor && !isLoggedIn) return;

  //     const query = contractInteractor.createQuery({
  //       func: new ContractFunction('getWhitelistedAddress'),
  //       args: [
  //         new AddressValue(new Address(address)),
  //         BytesValue.fromUTF8(presaleId)
  //       ],
  //     });
  //     const resultsParser = new ResultsParser();
  //     const response = await networkProvider.queryContract(query);
  //     const endpointDefinition = contractInteractor.getEndpoint('getWhitelistedAddress');
  //     const res = resultsParser.parseQueryResponse(
  //       response,
  //       endpointDefinition
  //     );
  //     const value = res.firstValue?.valueOf();

  //     if(value.toNumber() === 1) {
  //       setIsWhitelist(true);
  //     }
  //   })();
  // }, [contractInteractor, hasPendingTransactions]);

  const [isButtonActive, setButtonActive] = useState(false);

  const [tokenId, setTokenId] = useState('CYBER-489c1c');
  const [goal, setGoal] = useState(4000000);
  const [soldAmount, setSoldAmount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [minPurchase, setMinPurchase] = useState(1.2);
  const [maxPurchase, setMaxPurchase] = useState(120);
  const [price, setPrice] = useState(1000 / 1.2);
  const [startTime, setStartTime] = useState(0);
  const [whitelistStartTime, setWhitelistStartTime] = useState(0);
  useEffect(() => {
    (async () => {
      if (!contractInteractor) return;

      const query = contractInteractor.createQuery({
        func: new ContractFunction('getPresaleById'),
        args: [BytesValue.fromUTF8(presaleId)]
      });
      const resultsParser = new ResultsParser();
      const response = await networkProvider.queryContract(query);
      const endpointDefinition =
        contractInteractor.getEndpoint('getPresaleById');
      const res = resultsParser.parseQueryResponse(
        response,
        endpointDefinition
      );
      const value = res.firstValue?.valueOf();

      setTokenId(value.token.toString());
      // setGoal(2500000);
      const left = convertWeiToEgld(value.amount, TOKEN_DECIMAL);
      setSoldAmount(goal - left);
      const percentage = new BigNumber(4000000)
        .minus(left)
        .dividedBy(new BigNumber(4000000))
        .multipliedBy(100)
        .toString();
      setPercentage(parseInt(percentage.toString()));
      // setMinPurchase(convertWeiToEgld(value.min_purchase, 3));
      // setMaxPurchase(convertWeiToEgld(value.max_purchase, 3));
      // console.log(convertWeiToEgld(value.price, 12));
      // setPrice(convertWeiToEgld(value.price, 12));
      setStartTime(value.start.toNumber() * SECOND_IN_MILLI);
      // setWhitelistStartTime((value.start.toNumber() - 900) * SECOND_IN_MILLI);
      //setStartTime(Date.now() + 6000);
      // setWhitelistStartTime(Date.now() + 6000);
    })();

    // (async () => {
    //   if (!contractInteractor) return;

    //   if (isLoggedIn) {
    //     // console.log('whitelist');
    //     const query = contractInteractor.createQuery({
    //       func: new ContractFunction('getVipWhitelistedAddress'),
    //       args: [
    //         new AddressValue(new Address(address)),
    //         BytesValue.fromUTF8(presaleId)
    //       ]
    //     });
    //     const resultsParser = new ResultsParser();
    //     const response = await networkProvider.queryContract(query);
    //     const endpointDefinition = contractInteractor.getEndpoint(
    //       'getVipWhitelistedAddress'
    //     );
    //     const res = resultsParser.parseQueryResponse(
    //       response,
    //       endpointDefinition
    //     );
    //     const value = res.firstValue?.valueOf();
    //     // console.log(value.toNumber());

    //     if (value.toNumber() === 1) {
    //       setIsWhitelist(true);
    //     }
    //   }
    // })();
  }, [contractInteractor, hasPendingTransactions]);

  const [value, setValue] = useState(0);

  const handleInput = (e: any) => {
    // setValue(e.target.value);
    if (e.target.value > maxPurchase) {
      setValue(maxPurchase);
    } else {
      setValue(e.target.value);
      // if (e.target.value >= minPurchase) {
      //   setValue(e.target.value);
      // } else {
      //   setValue(minPurchase);
      // }
    }
  };

  const handlePurchase = async () => {
    // if (value < minPurchase || value > maxPurchase) return;
    // if (isWhitelist) {
    //   if (Date.now() < whitelistStartTime) return;
    // } else {
    //   if (Date.now() < startTime) return;
    // }
    // let egldAmount = value;
    if (value < minPurchase) {
      setValue(minPurchase);
      // egldAmount = minPurchase;
      return;
    }

    // handle buy
    const tokenAmount = new BigNumber(value).div(0.0012).toFixed(0);
    const price = new BigNumber(
      (parseInt(tokenAmount) * 0.0012).toFixed(4)
    ).multipliedBy(Math.pow(10, 18));

    console.log('tokenAmount: ', tokenAmount);
    const args = [
      BytesValue.fromUTF8(presaleId),
      new BigUIntValue(
        TokenPayment.fungibleFromBigInteger(
          tokenId,
          tokenAmount,
          TOKEN_DECIMAL
        ).valueOf()
      )
    ];

    // const computedAmount = new BigNumber(value).multipliedBy(Math.pow(10, 18) * 0.0012);
    // const roundTokenAmount = computedAmount.toFixed(0, BigNumber.ROUND_DOWN);
    // const price = new BigNumber(roundTokenAmount).multipliedBy(0.0012);
    // console.log('price: ', price);
    // console.log('roundTokenAmount: ', roundTokenAmount);

    // const args = [
    //   BytesValue.fromUTF8(presaleId),
    //   new BigUIntValue(
    //     TokenPayment.fungibleFromBigInteger(
    //       tokenId,
    //       roundTokenAmount
    //     ).valueOf()
    //   )
    // ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = new TransactionPayload(`purchase@${argumentsString}`);
    console.log(data.toString());

    const tx = {
      data: data.toString(),
      gasLimit: 20000000,
      receiver: PRESALE_CONTRACT_ADDRESS,
      value: `${price}`
    };

    await refreshAccount();
    await sendTransactions({
      transactions: tx
    });
  };

  const handleSetButtonActive = () => {
    if (!isButtonActive) setButtonActive(true);
  };

  const handleMax = () => {
    if (isLoggedIn) {
      if (convertWeiToEgld(account.balance, 18) > 0.005) {
        if (maxPurchase < convertWeiToEgld(account.balance, 18)) {
          setValue(maxPurchase);
        } else {
          setValue(convertWeiToEgld(account.balance, 18) - 0.005);
        }
      }
    }
  };

  return (
    <div className='container'>
      <video className='videoCPC' muted autoPlay loop>
        <source src={Video} type='video/mp4' />
      </video>
      ;
      <Row>
        <Col lg={6} className='container-left'>
          <h1 className='cpc-styled'>$CYBER Presale</h1>
          <h3>Cyberpunk City</h3>
          <p>
            Cyberpunk City is an upcoming blockchain-based NFT MMO (Massively
            Multiplayer Online) game that surrounds the #Metaverse from creative
            tools to true digital ownership and fascinating experiences.
          </p>
          <div className='container-left-buttons'>
            <a
              href='https://cyberpunkcity.com/whitepaper'
              target='_blank'
              rel='noreferrer'
            >
              <button className='is-style-cpc-btn-styled'>WHITEPAPER</button>
            </a>
            <a
              href='https://cyberpunkcity.com/'
              target='_blank'
              rel='noreferrer'
            >
              <button className='is-style-cpc-btn-styled'>WEBSITE</button>
            </a>
            <a
              href='https://dapp.cyberpunkcity.com/'
              target='_blank'
              rel='noreferrer'
            >
              <button className='is-style-cpc-btn-styled'>dAPP</button>
            </a>
          </div>
        </Col>
        <Col lg={6} className='container-right'>
          <div className='presale-container'>
            <div className='presale-container-status'>
              <div className='presale-container-status-left'>
                <div className='presale-container-status-phase-1'>
                  PHASE 3/3
                </div>
              </div>
              <div className='presale-container-status-right'>
                PRESALE ENDED!
              </div>
            </div>
            <BorderLinearProgress variant='determinate' value={65} />
            <div className='presale-container-status-sale-amount'>
              2,580,888 out of 4,000,000 CYBER<br />
              <p>1000 CYBER = 1.2 EGLD</p>
            </div>
          </div>
          <div className='presale-container'>
            <div className='presale-container-status'>
              <div className='presale-container-status-left'>
                <div className='presale-container-status-phase-1'>
                  PHASE 2/3
                </div>
              </div>
              <div className='presale-container-status-right'>SOLD OUT!</div>
            </div>
            <BorderLinearProgress variant='determinate' value={100} />
            <div className='presale-container-status-sale-amount'>
              3,500,000 out of 3,500,000 CYBER<br />
              <p>1000 CYBER = 1.1 EGLD</p>
            </div>
          </div>
          <div className='presale-container'>
            <div className='presale-container-status'>
              <div className='presale-container-status-left'>
                <div className='presale-container-status-phase-1'>
                  PHASE 1/3
                </div>
              </div>
              <div className='presale-container-status-right'>SOLD OUT!</div>
            </div>
            <BorderLinearProgress variant='determinate' value={100} />
            <div className='presale-container-status-sale-amount'>
              2,500,000 out of 2,500,000 CYBER<br />
              <p>1000 CYBER = 1 EGLD</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
