import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';
import Home from './pages/Home';

export const routeNames = {
  home: '/',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Cyberpunk City',
    component: Home
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • $CYBER Token Presale`
    : `$CYBER Token Presale ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
