import React from 'react';
import { DappProvider } from '@elrondnetwork/dapp-core/wrappers';
import { BrowserRouter as Router } from 'react-router-dom';
// import '@elrondnetwork/dapp-core/build/index.css';
import { ENVIRONMENT } from 'config';
import ContextWrapper from './ContextWrapper';

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={ENVIRONMENT}
        customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
      >
        <ContextWrapper />
      </DappProvider>
    </Router>
  );
};

export default App;
